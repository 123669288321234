import { ReactNode } from "react";
import {
  Box,
  Button,
  Container,
  Stack,
  SimpleGrid,
  Image,
  Text,
  Link,
  Flex,
  useColorModeValue,
} from "@chakra-ui/react";

import { URL_LOGO, URL_PDF } from "../../constants";

import { FaInstagram, FaTwitter } from "react-icons/fa";

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

const Footer = () => {
  return (
    <Box>
      <Container as={Stack} maxW={"6xl"} py={10}>
        <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={8}>
          <Stack align={"flex-start"}>
            <ListHeader>Links</ListHeader>
            <Link href={URL_PDF}>Phi-Force Manual</Link>
            <Link href={"mailto:partyofbums@gmail.com"}>Contact Us</Link>
          </Stack>

          <Stack align={"flex-start"}>
            <ListHeader>Social</ListHeader>
            <Button
              cursor={"pointer"}
              as={"a"}
              href={"https://www.twitter.com/partyofbums"}
              target="_blank"
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              mt="2"
              mr="2"
            >
              <FaTwitter color="#1da1f2" size={20} />
              <Text ml={2}>Twitter</Text>
            </Button>
            <Button
              cursor={"pointer"}
              as={"a"}
              href={"https://www.instagram.com/partyofbums"}
              target="_blank"
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              mt="2"
              mr="2"
            >
              <FaInstagram color="#1da1f2" size={20} />
              <Text ml={2}>Instagram</Text>
            </Button>
          </Stack>

          <Stack align={"flex-start"}>
            <ListHeader>
              🛒🇺🇸🇷🇺🇮🇱🇮🇷🇪🇬🇯🇲🇺🇦☦️ and all the rest of y'all...
            </ListHeader>
            <ListHeader>We are bums on this planet.</ListHeader>
            <ListHeader>
              Sheep, shepards, draconians? What's the difference? We all gotta
              eat!
            </ListHeader>
          </Stack>
        </SimpleGrid>
      </Container>

      <Box py={10}>
        <Flex
          align={"center"}
          _before={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: useColorModeValue("gray.200", "gray.700"),
            flexGrow: 1,
            mr: 8,
          }}
          _after={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: useColorModeValue("gray.200", "gray.700"),
            flexGrow: 1,
            ml: 8,
          }}
        >
          <Image
            boxSize="50px"
            objectFit="cover"
            borderRadius="full"
            src={URL_LOGO}
            alt=""
          />
        </Flex>
        <Text pt={6} fontSize={"sm"} textAlign={"center"}>
          © 2022 Party Of Bums, Party For Bums
        </Text>
      </Box>
    </Box>
  );
};

export default Footer;
