import {
  Container,
  SimpleGrid,
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import { BsPentagon } from "react-icons/bs";

import { GiEgyptianSphinx } from "react-icons/gi";
import { FaInfinity } from "react-icons/fa";

import { ReactElement } from "react";

interface FeatureProps {
  text: string;
  iconBg: string;
  icon?: ReactElement;
}

const Feature = ({ text, icon, iconBg }: FeatureProps) => {
  return (
    <Stack direction={"row"} align={"center"}>
      <Flex
        w={12}
        h={12}
        align={"center"}
        justify={"center"}
        rounded={"full"}
        bg={iconBg}
      >
        {icon}
      </Flex>
      <Text fontWeight={600} color="white">
        {text}
      </Text>
    </Stack>
  );
};

export default function Information() {
  return (
    <Box id="details" bg={"purple.500"}>
      <Container maxW={"6xl"} py={16}>
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          spacing={10}
          alignItems="center"
        >
          <Stack
            spacing={{ base: 12, md: 4 }}
            // divider={
            //   <StackDivider
            //     borderColor={useColorModeValue("gray.100", "gray.700")}
            //   />
            // }
          >
            <Feature
              icon={<Icon as={FaInfinity} color={"yellow.500"} w={10} h={10} />}
              iconBg={useColorModeValue("yellow.100", "yellow.900")}
              text={"Infinity"}
            />
            <Feature
              icon={
                <Icon as={GiEgyptianSphinx} color={"orange"} w={10} h={10} />
              }
              iconBg={useColorModeValue("green.100", "green.900")}
              text={"Eternity"}
            />
            <Feature
              icon={<Icon as={BsPentagon} color={"purple.500"} w={10} h={10} />}
              iconBg={useColorModeValue("purple.100", "purple.900")}
              text={"Perfection"}
            />
          </Stack>

          <Stack spacing={{ base: 12, md: 4 }}>
            <Heading color="white">Party of Bums</Heading>
            <Text color={"black"} fontSize={"lg"}>
              🏳️‍🌈 Green, Purple and Partisan
            </Text>
            <Text color={"black"} fontSize={"lg"}>
              Bums of the world unite!
            </Text>
            <Text color={"black"} fontSize={"lg"}>
              <u>Membership Requirements:</u> You are Rags and want to be
              Riches. You dress to Impress! All you need is{" "}
              <a href="https://youtu.be/_7xMfIp-irg">love</a>, and a little bit
              Food and Shelter!
            </Text>
            <Text color={"yellow.400"} fontSize={"lg"}>
              🛒🇺🇸🇷🇺🇮🇱🇮🇷🇪🇬🇯🇲🇺🇦☦️ and all the rest...
            </Text>
            <Text color={"black"} fontSize={"lg"}>
              Meta Modernism At Its Finest, Founded February 23, 2022
              (2022-02-23-23-20) Venice Beach Time
            </Text>
            <Text color={"white"} fontSize={"lg"}>
              The anti-ugly-culture movement.
            </Text>
          </Stack>
        </SimpleGrid>
      </Container>
    </Box>
  );
}
